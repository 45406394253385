import React from 'react'
import Helmet from 'react-helmet'

import ContactForm from '../components/ContactForm'
import Root from '../layouts/Root'
import Testimonials from '../components/Testimonials'

import config from '../config'

const Referenties = () => (
  <Root>
    {/* prettier-ignore */}
    <Helmet>
      <title>{`Referenties | ${config.title}`}</title>
      <meta property="og:title" content={`Referenties | ${config.title}`} />
      <meta name="description" content="Referenties van klanten van W&R beveiliging. Een overzicht van tevreden opdrachtgevers. Van evenementen tot bedrijven. Meer weten? Lees snel!" />
      <meta property="og:description" content="Referenties van klanten van W&R beveiliging. Een overzicht van tevreden opdrachtgevers. Van evenementen tot bedrijven. Meer weten? Lees snel!" />
    </Helmet>

    <main>
      <h1 className="t-heading-l t-center" style={{ marginBottom: 80 }}>
        Referenties
      </h1>
      <Testimonials filled />
      <ContactForm />
    </main>
  </Root>
)

export default Referenties
